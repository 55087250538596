import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import * as XLSX from 'xlsx';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';

const SubirExcel = () => {
    const fileInputRef = useRef(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const [xlsxData, setXlsxData] = useState([]);
    const navigate = useNavigate();

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setSelectedFile(file);

        // Verificar si el archivo es xlsx
        if (file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
            // Resto del código de SubirExcel para archivos xlsx...
        } else {
            alert('Formato de archivo no válido. Por favor, selecciona un archivo XLSX.');
            setSelectedFile(null);
            setXlsxData([]);
        }
    };

    const handleUploadConfirmed = () => {
        // Resto del código de SubirExcel para subir el archivo...
    };

    const handleCancelUpload = () => {
        setSelectedFile(null);
        setXlsxData([]);
    };

    const openFilePicker = () => {
        fileInputRef.current.click();
    };

    const handleCreateTemplate = () => {
        // Crear un nuevo libro de trabajo
        const workbook = XLSX.utils.book_new();

        // Crear una hoja de trabajo con datos de ejemplo
        const data = [
            ['descripcion', 'codigo'],
            ['Ejemplo 1', '001'],
            ['Ejemplo 2', '002'],
            ['Ejemplo 3', '003'],
        ];

        // Crear una hoja y agregar los datos
        const worksheet = XLSX.utils.aoa_to_sheet(data);
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Plantilla');

        // Generar el archivo XLSX
        XLSX.writeFile(workbook, 'plantilla.xlsx');
    };

    return (
        <div>
            {selectedFile ? (
                <div>
                    <h3>Vista previa del archivo XLSX:</h3>
                    {/* Resto del código de SubirExcel para mostrar la vista previa... */}
                </div>
            ) : (
                <div>
                    <input
                        type="file"
                        style={{ display: 'none' }}
                        ref={fileInputRef}
                        onChange={handleFileChange}
                        accept=".xlsx"
                    />
                    <button onClick={openFilePicker}>Seleccionar archivo</button>
                    <button onClick={handleCreateTemplate}>Descargar plantilla </button>
                </div>
            )}
        </div>
    );
};

const ModalSubirExcel = () => {
    const [modalIsOpen, setModalIsOpen] = useState(false); // Estado para controlar la apertura del modal
    const navigate = useNavigate();

    const openModal = () => {
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };

    return (
        <div>
            <Button variant="contained" onClick={openModal}>Abrir Modal</Button>
            <Modal open={modalIsOpen} onClose={closeModal}>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100vh',
                    }}
                >
                    <div>
                        <SubirExcel />
                        <Button variant="contained" onClick={closeModal}>Cerrar Modal</Button>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default ModalSubirExcel;