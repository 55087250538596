import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import Dashboard from "./paginas/dashboard";
import Usuario from "./paginas/usuarios";
import Producto from "./paginas/productos";
import Reportes from "./paginas/reportes";
import SubirExcel from "./paginas/subirexcel";
import { RouterLayout } from "./componentes/RouterLayout";
import Login from "./paginas/login";
import { useState } from "react";
import Empresas from "./paginas/empresas";
import Impresion from "./paginas/impresion";


export const AppRutas = () => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    return (
        <Routes>
            <Route path="/" element={<RouterLayout />}>
                <Route
                    path="/"
                    element={isLoggedIn ? <Dashboard /> : <Navigate to="/login" />}
                />
                <Route path="/Empresas" element={<Empresas />} />
                <Route path="/Usuarios" element={<Usuario />} />
                <Route path="/Productos" element={<Producto />} />
                <Route path="/Reportes" element={<Reportes />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/subirexcel" element={<SubirExcel />} />
                <Route path="/impresion" element={<Impresion />} />
            </Route>
            <Route
                path="/login" element={<Login setIsLoggedIn={setIsLoggedIn} />}
            />
        </Routes>
    );
};