import * as React from 'react';
import Box from '@mui/material/Box';
import axios from "axios";
import ModalUsuarios from "../componentes/modalusuario";
import { useState, useEffect } from 'react';
import Table from "react-bootstrap/Table";
import Container from "react-bootstrap/Container";
import { Button } from 'react-bootstrap';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ModalEmpresas from '../componentes/modalempresa';
import EditarEmpresa from '../componentes/editarempresa';

export default function TablaEmpresa() {
    const URIEmpresas = "https://demo.edgarleal.es/web/obtener_empresas.php"
    const [empresa, setEmpresa] = useState([])
    useEffect(() => {
        getEmpresas()
    },[])

    //procedimiento para mostrar los productos
    const getEmpresas = async () => {
        const res = await axios.get(URIEmpresas)
        setEmpresa(res.data)
        console.log(res.data)
    }

    //procedimiento para eliminar un producto
    const deleteEmpresas = async (idEmpresas) => {
        try{
            // Mostrar confirmación al usuario
            const confirmDelete = window.confirm('¿Estás seguro de que deseas eliminar esta empresa?');
            // Si el usuario cancela, no realizar la eliminación
            if (!confirmDelete) {
                return;
            }

            const res = await axios.post(`https://demo.edgarleal.es/web/eliminar_empresa.php?id=${idEmpresas}`);
            console.log(idEmpresas);
            getEmpresas();


        } catch (error) {
            console.log(error);
        }
    };

    return (

        <Container maxWidth="xl">
            <Box sx={{ marginLeft: "78%" }}>
                <ModalEmpresas />
            </Box>
            <div className="table-responsive">
                <Table striped bordered hover size="sm">
                <thead>
                    <tr>
                        <th scope="col">ID</th>
                        <th scope="col">Nombre</th>
                        <th scope="col">Rut</th>
                        <th scope="col">Dirección</th>
                        <th scope="col">Acciones</th>
                    </tr>
                </thead>
                    <tbody style={{ height: "100%" }}>
                        {empresa.map(item => (
                            <tr key={item.idEmpresa}>
                                <td className='centrar'>{item.idEmpresa}</td>
                                <td className='centrar'>{item.empresa}</td>
                                <td className='centrar'>{item.rut}</td>
                                <td className='centrar'>{item.direccion}</td>
                                <td style={{ display: 'flex', gap: '10px' }}>
                                    <Button variant="danger" onClick={() => deleteEmpresas(item.idEmpresa)}><DeleteForeverIcon /></Button>
                                    <EditarEmpresa empresa={item} />
                                </td>
                            </tr>
                        ))}
                </tbody>
            </Table>
            </div>
        </Container >
    );
}