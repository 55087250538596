import * as React from 'react';
import { Container, Grid, Button } from '@mui/material';
import ModalImpresion from '../componentes/modalimpresion';
import ModalImpresionSinEspecificar from '../componentes/ModalImpresionSinEspecificar'; // Asegúrate de importar tu nuevo modal

export default function Impresion() {
    return (
        <Container>
            <Grid container spacing={2} justifyContent="center">
                <Grid item>
                    <ModalImpresion />
                </Grid>
                <Grid item>
                    <ModalImpresionSinEspecificar /> {/* Agrega el nuevo modal aquí */}
                </Grid>
            </Grid>
        </Container>
    );
}
