import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import "./modal.css";
import LogoRFID from "../imagenes/logorfid.png";
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import {Button} from 'react-bootstrap';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ComboBoxEstados } from '../componentes/comboBoxs';
import Form from 'react-bootstrap/Form';




const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "50%",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};


export default function ModalProducto({ producto }) {
    const URI = "http://demo.edgarleal.es/web/actualizar_producto.php"
    const URIEstados = "http://demo.edgarleal.es/web/obtener_estados.php"
    const [descripcion, setDescripcion] = useState(producto.descripcion)
    const [adicional, setAdicional] = useState(producto.adicional)
    const [estado, setEstado] = useState(producto.idEstado)
    const [id, setId] = useState(producto.idProductos)
    const [listEstados, setListestados] = useState([]);

    const navigate = useNavigate()

    useEffect(() => {
        // Función para realizar la solicitud HTTP usando Axios
        const fetchData = async () => {
            try {
                const response = await axios.get('http://demo.edgarleal.es/web/obtener_estados.php');
                setListestados(response.data);
            } catch (error) {
                console.error('Error al obtener los estados:', error);
            }
        };

        fetchData();
    }, []);


    const GuardarEditProducto = async (e) => {
        e.preventDefault();

        // Verificar si los campos requeridos están vacíos
        if (!descripcion || !adicional ) {
            // Mostrar mensaje de error o realizar alguna acción
            alert('Por favor, complete todos los campos');
            return;

        }

        
        try {
            // Realizar la solicitud POST
            await axios.post(URI, { id: id, descripcion: descripcion, adicional: adicional, estado: estado })

            // Mostrar mensaje de éxito
            alert('Producto actualizado');
            // Cerrar el modal (opcional)
            handleClose();

            // Opcional: Reiniciar los valores del formulario

            // Navegar a la página de usuarios 
            window.location.reload();

        } catch (error) {
            // Manejar errores de la solicitud POST, si es necesario
            console.error(error);
        }
    }

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <div>
            <Button variant="primary" onClick={handleOpen} style={{ minWidth: '30px', padding: '5px', alignItems: 'center', justifyContent: 'center' }}
 ><EditIcon  /></Button>
            <Modal 
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Editar producto
                    </Typography>

                    <Divider variant="middle" />
                    <form onSubmit={GuardarEditProducto}>
                        <div className="input-container">
                            <div>
                                <div className="input-container">
                                    
                                    <TextField
                                        className="input-text"
                                        id="LabelModalUsuario"
                                        label="Descripción"
                                        variant="outlined"
                                        defaultValue={producto.descripcion}
                                        onChange={(e) => setDescripcion(e.target.value)}
                                    />
                                </div>
                                <div className="input-container">
                                    <Form.Select aria-label="Default select example" style={{ width: '50%' }} defaultValue={producto.idestado} onChange={(e) => setEstado(e.target.value)}>
                                        {listEstados.map((estado) => (
                                            <option key={estado.idEstado} value={estado.estado}  >
                                                {estado.estado}
                                            </option>
                                        ))}
                                    </Form.Select>
                                    <TextField
                                        className="input-text"
                                        id="LabelModalAdicional"
                                        label="Adicional"
                                        variant="outlined"
                                        defaultValue={producto.adicional}
                                        onChange={(e) => setAdicional(e.target.value)}
                                    />
                                </div>
                                <div className="input-container"></div>
                                <div className="input-container">
                                    <TextField
                                        className="input-text"
                                        id="standard-read-only-input"
                                        label="EPC"
                                        defaultValue="000000000000000000000000"
                                        InputProps={{ readOnly: true }}
                                        value={producto.epc}
                                    />
                                    <TextField
                                        className="input-text"
                                        id="standard-read-only-input"
                                        label="Código de barra/QR"
                                        defaultValue="12345678910"
                                        InputProps={{ readOnly: true }}
                                        value={producto.codigobarra}
                                    />
                                </div>
                                <div className="input-container">
                                    <Button type='submit' variant='primary'>Guardar</Button>
                                    <Button variant="danger">Cancelar</Button>
                                </div>
                            </div>
                            <Divider orientation="vertical" flexItem />
                            <div>
                                <img className="tamano-imagen" src={LogoRFID} alt="Imagen" />
                            </div>
                        </div>
                    </form>
                </Box>
            </Modal>
        </div>
    );
}