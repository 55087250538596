import React from 'react';
import {
    Chart as ChartJS,
    ArcElement,
    Tooltip,
    Legend,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
} from 'chart.js';
import { Pie, Bar } from 'react-chartjs-2';

ChartJS.register(
    ArcElement,
    Tooltip,
    Legend,
    CategoryScale,
    LinearScale,
    BarElement,
    Title
);

const labels = ['SA02', 'SA03', 'SA04', 'Bodega', 'Local Chicureo'];

export const optionsUbicacion = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top',
        },
        title: {
            display: true,
            text: 'Productos por ubicación',
        },
    },
};

export const optionsCategoria = {
    responsive: true,
    plugins: {
        title: {
            display: true,
            text: 'Estados por productos',
        },
    },
};

export const data2 = {
    labels,
    datasets: [
        {
            label: 'Productos',
            data: labels.map(() => Math.floor(Math.random() * 100)),
            backgroundColor: 'rgba(11, 7, 221, 0.795)',
        },
    ],
};

export const data = {
    labels: ['Nuevo', 'Reparacion', 'Merma'],
    
    datasets: [
        {
            label: 'Productos en esta categoria',
            data: [19, 3, 5],
            backgroundColor: [
                'rgba(250, 11, 11, 0.705)',
                'rgba(18, 58, 235, 0.74)',
                'rgba(255, 206, 86, 0.2)',
                'rgba(75, 192, 192, 0.2)',
                'rgba(153, 102, 255, 0.2)',
                'rgba(255, 159, 64, 0.2)',
            ],
            borderColor: [
                '#ee0e0e',
                '#3648eb',
                'rgba(255, 206, 86, 1)',
                'rgba(75, 192, 192, 1)',
                'rgba(153, 102, 255, 1)',
                'rgba(255, 159, 64, 1)',
            ],
            borderWidth: 1,
        },
    ],
};

export function GraficoCategorias() {
    return <Pie options={optionsCategoria}  data={data} />;
}

export function GraficoUbicacion() {
    return <Bar options={optionsUbicacion} data={data2} />;
}
