import React, { useState, useEffect } from 'react';
import { Button, Modal, Form, Table } from 'react-bootstrap';

const apiProductos = "https://demo.edgarleal.es/web/obtener_descripciones.php";
const apiUrl = 'http://localhost/zebra.php';
const apiEstados = "https://tu-api.com/estados";
const apiUbicaciones = "https://tu-api.com/ubicaciones";
//const apiUrl = 'http://192.168.0.112:8080/inicio.php';

export default function ModalImpresion() {
    const [show, setShow] = useState(false);
    const [cantidadProductos, setCantidadProductos] = useState(1);
    const [productosDescripciones, setProductosDescripciones] = useState([]);
    const [productoSeleccionado, setProductoSeleccionado] = useState(null);
    const [codigos, setCodigos] = useState(['AF0000000001']); // Inicializa con un código por defecto
    const [editIndex, setEditIndex] = useState(-1);
    const [estados, setEstados] = useState([]);
    const [ubicaciones, setUbicaciones] = useState([]);

    useEffect(() => {
        fetch(apiProductos)
            .then(response => {
                if (!response.ok) {
                    throw new Error('La respuesta de la red no fue correcta');
                }
                return response.json();
            })
            .then(data => {
                setProductosDescripciones(data);
            })
            .catch(error => {
                console.error('Hubo un problema con la operación de búsqueda:', error);
            });

        fetch(apiEstados)
            .then(response => {
                if (!response.ok) {
                    throw new Error('La respuesta de la red no fue correcta');
                }
                return response.json();
            })
            .then(data => {
                setEstados(data);
            })
            .catch(error => {
                console.error('Hubo un problema con la operación de búsqueda:', error);
            });

        fetch(apiUbicaciones)
            .then(response => {
                if (!response.ok) {
                    throw new Error('La respuesta de la red no fue correcta');
                }
                return response.json();
            })
            .then(data => {
                setUbicaciones(data);
            })
            .catch(error => {
                console.error('Hubo un problema con la operación de búsqueda:', error);
            });
    }, []);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const generateSequentialCodes = (cantidad, startCode = 'AF0000000001') => {
        const startNumber = parseInt(startCode.slice(2), 10);
        return Array.from({ length: cantidad }, (_, i) =>
            'AF' + String(startNumber + i).padStart(10, '0')
        );
    };

    const handleCantidadChange = (e) => {
        const cantidad = parseInt(e.target.value);
        setCantidadProductos(cantidad);
        setCodigos(generateSequentialCodes(cantidad)); // Genera códigos secuenciales
    };

    const handleSelectChange = (e) => {
        const selectedProductId = parseInt(e.target.value);
        const selectedProduct = productosDescripciones.find(producto => producto.idDescripcion === selectedProductId);
        setProductoSeleccionado(selectedProduct);
    };

    const handleImprimir = () => {
        if (!productoSeleccionado) {
            console.error("No se ha seleccionado ningún producto.");
            return;
        }

        console.log("Datos enviados a la API:");
        console.log("Cantidad de productos:", cantidadProductos);
        console.log("Productos:");

        const productosParaEnviar = codigos.map(codigo => ({
            producto: productoSeleccionado.descripcion,
            codigo: codigo,
        }));

        productosParaEnviar.forEach(producto => console.log(producto));

        const url = apiUrl;
        const data = {
            modelos: productosParaEnviar
        };

        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                console.log("La solicitud POST fue exitosa.");
            })
            .catch(error => {
                console.error('There was a problem with the fetch operation:', error);
                console.log("La solicitud POST falló.");
            });
    };

    const handleEditClick = (index) => {
        setEditIndex(index);
    };

    const handleCodigoChange = (e, index) => {
        const newCodigos = [...codigos];
        newCodigos[index] = e.target.value;
        setCodigos(newCodigos);
    };

    return (
        <div>
            <Button variant="primary" onClick={handleShow}>
                Imprimir productos
            </Button>

            <Modal
                show={show}
                onHide={handleClose}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                style={{ marginTop: '50px', marginLeft: '50px' }}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Imprimir productos</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3" controlId="formSelectProducto">
                            <Form.Label>Seleccione un producto</Form.Label>
                            <Form.Select onChange={handleSelectChange}>
                                <option value="">Seleccionar producto</option>
                                {productosDescripciones.map(producto => (
                                    <option key={producto.idDescripcion} value={producto.idDescripcion}>{producto.descripcion}</option>
                                ))}
                            </Form.Select>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formCantidad">
                            <Form.Label>Seleccione la cantidad de productos a imprimir</Form.Label>
                            <Form.Control type="number" value={cantidadProductos} onChange={handleCantidadChange} />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formSelectEstado">
                            <Form.Label>Seleccione el estado</Form.Label>
                            <Form.Select>
                                <option value="">Seleccionar estado</option>
                                {estados.map(estado => (
                                    <option key={estado.id} value={estado.id}>{estado.nombre}</option>
                                ))}
                            </Form.Select>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formSelectUbicacion">
                            <Form.Label>Seleccione la ubicación</Form.Label>
                            <Form.Select>
                                <option value="">Seleccionar ubicación</option>
                                {ubicaciones.map(ubicacion => (
                                    <option key={ubicacion.id} value={ubicacion.id}>{ubicacion.nombre}</option>
                                ))}
                            </Form.Select>
                        </Form.Group>
                        
                        <div style={{ maxHeight: '200px', overflowY: 'auto' }}>
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>Producto</th>
                                        <th>Código</th>
                                        <th>Tamaño</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Array.from({ length: cantidadProductos }).map((_, index) => (
                                        <tr key={index}>
                                            <td>{productoSeleccionado ? productoSeleccionado.descripcion : ''}</td>
                                            <td onClick={() => handleEditClick(index)}>
                                                {editIndex === index ? (
                                                    <Form.Control type="text" value={codigos[index]} onChange={(e) => handleCodigoChange(e, index)} />
                                                ) : (
                                                    <span>{codigos[index]}</span>
                                                )}
                                            </td>
                                            <td>{productoSeleccionado ? productoSeleccionado.tamaño : '10x3 cm'}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </div>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Cerrar
                    </Button>
                    <Button variant="primary" onClick={handleImprimir}>
                        Imprimir
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}
