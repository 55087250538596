import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import Container from "react-bootstrap/Container";
import { useState, useEffect } from 'react';
import axios from "axios";
import RoomIcon from '@mui/icons-material/Room';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import ModalProductos from "../componentes/modalproducto";
import ModalDetalleProducto from '../componentes/modaldetalleproducto';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "50%",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export default function TablaProductos() {
    const URIProductos = "https://demo.edgarleal.es/web/obtener_productos2.php";
    const [productos, setProductos] = useState([]);
    const [selectedImage, setSelectedImage] = useState('');
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        getProductos();
    }, []);

    const getProductos = async () => {
        try {
            const res = await axios.get(URIProductos);
            setProductos(res.data);
        } catch (error) {
            console.log(error);
        }
    };

    const deleteProducto = async (idProducto) => {
        try {
            const confirmDelete = window.confirm('¿Estás seguro de que deseas eliminar este producto?');
            if (!confirmDelete) {
                return;
            }

            await axios.post(`https://demo.edgarleal.es/web/eliminar_producto.php?id=${idProducto}`);
            getProductos();
        } catch (error) {
            console.log(error);
        }
    };

    const openImageModal = (image) => {
        setSelectedImage(image);
        setShowModal(true);
    };

    const closeImageModal = () => {
        setShowModal(false);
        setSelectedImage('');
    };

    const columns = [
        { field: 'descripcion', headerName: 'Descripción', flex: 1 },
        { field: 'estado', headerName: 'Estado', flex: 0.5 },
        { field: 'epc', headerName: 'EPC', flex: 1 },
        { field: 'lugar', headerName: 'Ubicación', flex: 1 },
        { field: 'fechaproducto', headerName: 'Fecha de registro', flex: 1 },
        {
            field: 'coordenadas', headerName: 'Coordenadas', flex: 1, renderCell: (params) => (
                <a href={`https://www.google.com/maps/place/${params.row.latitud},${params.row.longitud}`} target="_blank" rel="noopener noreferrer">
                    <RoomIcon sx={{ fontSize: 31 }} /> <p>ver</p>
                </a>
            )
        },
        {
            field: 'foto', headerName: 'Foto', flex: 0.5, renderCell: (params) => (
                <div onClick={() => openImageModal(params.row.foto)}>
                    <img src={params.row.foto} alt="Producto" style={{ width: 70, height: 70 }} />
                </div>
            )
        },
        {
            field: 'acciones', headerName: 'Acciones', flex: 1, renderCell: (params) => (
                <div style={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
                    <Button
                        variant="danger"
                        onClick={() => deleteProducto(params.row.idProductos)}
                        style={{ minWidth: '30px', padding: '5px', height: '35px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                    >
                        <DeleteForeverIcon fontSize="small" />
                    </Button>
                    <ModalProductos
                        producto={params.row}
                    
                    />
                    <ModalDetalleProducto
                        producto={params.row}
                    />
                </div>
            )
        },
    ];


    const rows = productos.map((item, index) => ({
        id: index,
        ...item
    }));

    return (
        <body>
            <Container maxWidth="xl">
                <div style={{ height: '100%', width: '100%' }}>
                    <DataGrid
                        rows={rows}
                        columns={columns}
                        initialState={{
                            pagination: {
                                paginationModel: { page: 0, pageSize: 10 },
                            },
                        }}
                        pageSizeOptions={[5, 10]}
                        localeText={{

                            columnMenuSortAsc: 'Ordenar ascendente',
                            columnMenuSortDesc: 'Ordenar descendente',
                            columnMenuFilter: 'Filtrar',
                            columnMenuHideColumn: 'Ocultar',
                            columnMenuShowColumns: 'Mostrar columnas',
                            columnMenuManageColumns: 'Administrar columnas',
                            columnHeaderFiltersTooltipActive: (count) => `${count} filtros activos`,
                            columnHeaderFiltersLabel: 'Mostrar filtros',
                            columnHeaderSortIconLabel: 'Ordenar',
                            footerRowSelected: (count) => `${count.toLocaleString()} fila(s) seleccionada(s)`,
                            footerTotalRows: 'Total de filas:',
                            footerTotalVisibleRows: (visibleCount, totalCount) =>
                                `${visibleCount.toLocaleString()} de ${totalCount.toLocaleString()}`,
                            noRowsLabel: 'No hay filas',
                            noResultsOverlayLabel: 'No se encontraron resultados',
                            toolbarDensity: 'Densidad',
                            toolbarDensityLabel: 'Densidad',
                            toolbarDensityCompact: 'Compacto',
                            toolbarDensityStandard: 'Estándar',
                            toolbarDensityComfortable: 'Cómodo',
                            columnMenuUnsort: 'Desordenar',
                        }}
                    />
                </div>
                <Modal show={showModal} onHide={closeImageModal} sx={style}>
                    <Modal.Header closeButton>
                        <Modal.Title>Imagen</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <img src={selectedImage} alt="Imagen" style={{ width: '100%', height: 'auto', top: "70%" }} />
                    </Modal.Body>
                </Modal>
            </Container>
        </body>
    );
}
