import React, { useState } from 'react';
import { Button, Modal, Form, Table } from 'react-bootstrap';
import axios from 'axios';

const apiUrlZebra = 'http://localhost/zebra.php'; // Asegúrate de que la URL sea correcta y accesible
const apiUrlCodigoSE = 'https://demo.edgarleal.es/web/CodigoSE.php'; // Asegúrate de que la URL sea correcta y accesible

export default function ModalImpresionSinEspecificar() {
    const [show, setShow] = useState(false);
    const [cantidadProductos, setCantidadProductos] = useState(1);
    const [codigos, setCodigos] = useState(['SE0000000001']); // Inicializa con un código por defecto

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const generateSequentialCodes = (cantidad, startCode = 'SE0000000001') => {
        const startNumber = parseInt(startCode.slice(2), 10);
        return Array.from({ length: cantidad }, (_, i) =>
            'SE' + String(startNumber + i).padStart(10, '0')
        );
    };

    const handleCantidadChange = (e) => {
        const cantidad = parseInt(e.target.value);
        setCantidadProductos(cantidad);
        setCodigos(generateSequentialCodes(cantidad)); // Genera códigos secuenciales
    };

    const handleImprimir = () => {
        const dataCodigoSE = {
            codigos: codigos.map(codigo => ({
                codigo: codigo
            }))
        };

        console.log("Datos enviados a CodigoSE:", JSON.stringify(dataCodigoSE));

        axios.post(apiUrlCodigoSE, dataCodigoSE)
            .then(response => {
                console.log("La solicitud POST a CodigoSE fue exitosa.");
                console.log("Respuesta de CodigoSE:", response.data);
            })
            .catch(error => {
                console.error('Hubo un problema con la operación Axios:', error);
                console.log("La solicitud POST a CodigoSE falló.");
            });

        const dataZebra = {
            modelos: codigos.map(codigo => ({
                producto: "Sin Descripcion",
                codigo: codigo
            }))
        };

        axios.post(apiUrlZebra, dataZebra)
            .then(response => {
                console.log("La solicitud POST a Zebra fue exitosa.");
                // Puedes hacer algo con la respuesta de Zebra si es necesario
            })
            .catch(error => {
                console.error('Hubo un problema con la operación Axios:', error);
                console.log("La solicitud POST a Zebra falló.");
            });
    };

    return (
        <div>
            <Button variant="primary" onClick={handleShow}>
                Imprimir etiquetas sin especificar
            </Button>

            <Modal
                show={show}
                onHide={handleClose}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                style={{ marginTop: '50px', marginLeft: '50px' }}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Imprimir etiquetas sin especificar</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3" controlId="formCantidad">
                            <Form.Label>Seleccione la cantidad de etiquetas a imprimir</Form.Label>
                            <Form.Control type="number" value={cantidadProductos} onChange={handleCantidadChange} />
                        </Form.Group>

                        <div style={{ maxHeight: '200px', overflowY: 'auto' }}>
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>Código</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {codigos.map((codigo, index) => (
                                        <tr key={index}>
                                            <td>{codigo}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </div>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Cerrar
                    </Button>
                    <Button variant="primary" onClick={handleImprimir}>
                        Imprimir
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}
