import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import LogoRFID from '../imagenes/Logo animado.gif';
import Form from 'react-bootstrap/Form';
import {
    MDBContainer,
    MDBInput,
    MDBBtn,
    MDBIcon
} from 'mdb-react-ui-kit';

const Login = ({ setIsLoggedIn }) => {
    const [rut, setRut] = useState('');
    const [clave, setClave] = useState('');
    const [selectedEmpresa, setSelectedEmpresa] = useState('');
    const [empresas, setEmpresas] = useState([]);
    const [error, setError] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const fetchEmpresas = async () => {
            try {
                const response = await axios.get('https://demo.edgarleal.es/pda/spinnerempresas.php');
                setEmpresas(response.data);
            } catch (error) {
                console.error('Error al obtener empresas:', error);
                setError('Error al obtener empresas');
            }
        };

        fetchEmpresas();
    }, []);

    const handleRutChange = (e) => {
        setRut(e.target.value);
    };

    const handleClaveChange = (e) => {
        setClave(e.target.value);
    };

    const handleEmpresaChange = (e) => {
        setSelectedEmpresa(e.target.value);
    };

    const handleLogin = async (e) => {
        e.preventDefault();

        console.log('Datos enviados:', { rut, clave, idempresa: selectedEmpresa });

        try {
            const response = await axios.post('https://demo.edgarleal.es/web/login.php', { rut, clave, idempresa: selectedEmpresa });

            if (response.status === 200) {
                setIsLoggedIn(true);
                setError('');
                navigate('/dashboard');
            } else {
                setError('Error en la solicitud');
                console.log('Error en la solicitud:', response);
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                setError('Credenciales inválidas');
                alert('Credenciales inválidas');
                console.log('Credenciales inválidas:', error.response);
            } else {
                setError('Error en la solicitud HTTP');
                console.log('Error en la solicitud HTTP:', error);
            }
        }
    };

    return (
        <MDBContainer className="p-3 my-5 d-flex flex-column w-50">
            <div className="text-center mb-4" style={{ paddingBottom: "20px" }}>
                <img src={LogoRFID} style={{ width: '70%' }} alt="logo" />
            </div>

            <Form.Select className='mb-4' aria-label="Default select example" value={selectedEmpresa} onChange={handleEmpresaChange}>
                <option value="">Selecciona tu empresa</option>
                {empresas.map((empresa, index) => (
                    <option key={index} value={parseInt(empresa.idempresa)}>{empresa.empresa}</option>
                ))}
            </Form.Select>


            <MDBInput wrapperClass='mb-4' label='Rut' id='form1' value={rut} onChange={handleRutChange} />
            <MDBInput wrapperClass='mb-4' label='Contraseña' id='form2' type='password' value={clave} onChange={handleClaveChange} />

            <MDBBtn className="mb-4" onClick={handleLogin}>Ingresar</MDBBtn>

            <div className="text-center">
                <div className='d-flex justify-content-between mx-auto' style={{ width: '40%' }}>
                    Si no tienes contraseña,
                    Favor solicitala a soporte@rfid.cl
                </div>
            </div>
        </MDBContainer>
    );
};

export default Login;
