import React, { useState, useEffect } from 'react';
import Container from '@mui/material/Container';
import { GraficoCategorias, GraficoUbicacion } from '../componentes/graficoCategorias';
import { Box, Grid } from '@mui/material';

const commonStyles = {
  bgcolor: 'background.paper',
  borderColor: 'text.primary',
  m: 1,
  border: 1,
  width: 400,
  height: 400,
};
const margintop = {
  top: '50%'
}

export default function Dashboard() {
  const [nombreUsuario, setNombreUsuario] = useState('');

  useEffect(() => {
    // Lógica para obtener el nombre del usuario de la sesión
    const obtenerNombreUsuarioSesion = () => {
      // Verificar si el nombre de usuario está almacenado en el almacenamiento local del navegador
      const nombreUsuarioSesion = localStorage.getItem('nombreUsuario');
      if (nombreUsuarioSesion) {
        setNombreUsuario(nombreUsuarioSesion);
      } else {
        setNombreUsuario('Invitado'); // Establecer un valor predeterminado si no hay nombre de usuario en la sesión
      }
    };

    obtenerNombreUsuarioSesion();
  }, []);

  return (
    <Container style={{ marginTop: 50 }}>
      <h1>Reportes de productos </h1>
      
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <Box sx={{ ...commonStyles, borderRadius: 16 }}>
            <GraficoCategorias />
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <Box sx={{ ...commonStyles, borderRadius: 16}}>
            <GraficoUbicacion sx={{margintop}} />
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}